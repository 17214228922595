import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { url } from "../api/api";
import Navbar from "../component/Dasboard/Navbar";
import Dashboardsidenav from "../component/Dasboard/Dashboardsidenav";
import Activityindicator from "../Activityindicator";
import Success from "../img/success.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserTransactions = () => {
  const { userId } = useParams();
  const [transactions, setTransactions] = useState([]);

  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [paymentAddress, setPaymentAddress] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);

  const [isPopupOpen5, setIsPopupOpen5] = useState(false);
  const [isPopupOpen6, setIsPopupOpen6] = useState(false);

  const [withdrawal, setWithdrawalMethod] = useState([]);

  const [selectedWithdrwal, setSelectedWithdrawal] = useState("");
  const [withdrawalPlan, setWithdrawalplan] = useState(false);
  const [withdrawalamount, setWithdrawalamount] = useState("");
  const [withdrawalAdress, setWithdrawalAddress] = useState("");

  const handleOpenPopup5 = () => {
    setIsPopupOpen5(true);
  };

  const handleClosePopup5 = () => {
    setIsPopupOpen5(false);
  };

  const handleClosePopup6 = () => {
    setIsPopupOpen6(false);
  };

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/funding/fetchpaymentmethod`)
      .then((response) => response.json())
      .then((data) => setPayments(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handlePaymentChange = (event) => {
    const selectedPaymentName = event.target.value;
    setSelectedPayment(selectedPaymentName);
    const payment = payments.find((p) => p.paymentname === selectedPaymentName);
    setPaymentAddress(payment ? payment.paymentaddress : "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Construct the data to be sent to the backend
    const submissionData = {
      fundingtypename: selectedPayment,
      amount: depositAmount,
    };

    // Send data to backend

    const userid = JSON.parse(localStorage.getItem("userdata"));

    axios
      .post(`${url}/funding/addbalanceadmin/${userId}`, submissionData)
      .then((response) => {
        setIsLoading(false);
        setIsPopupOpen1(true);
        setIsPopupOpen(false);
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Fetch data from backend
    fetch(`${url}/withdrawal/fetchallwithdrawal`)
      .then((response) => response.json())
      .then((data) => setWithdrawalMethod(data.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleWithdrawalChange = (event) => {
    const selectedWithdrawalName = event.target.value;
    setSelectedWithdrawal(selectedWithdrawalName);
    console.log(selectedWithdrawalName);
    const withdraw = withdrawal.find((w) => w._id === selectedWithdrawalName);
    setWithdrawalplan(withdraw ? true : false);
  };

  const handleWithdrawalamountChange = (event) => {
    const Withdrawaldamount = event.target.value;
    setWithdrawalamount(Withdrawaldamount);
  };
  const handleWithdrawalAddressChange = (event) => {
    const Withdrawaladdress = event.target.value;
    setWithdrawalAddress(Withdrawaladdress);
  };

  const handleSubmitwithdrawal = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const userid = JSON.parse(localStorage.getItem("userdata"));
    // Construct the data to be sent to the backend
    const submissionData = {
      userid: userId,
      withdrawalid: selectedWithdrwal,
      withdrawalamount: withdrawalamount,
      withdrawaladdress: withdrawalAdress,
    };

    // Send data to backend

    axios
      .post(`${url}/withdrawal/makenewwithdrawaladmin`, submissionData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsLoading(false);
        setIsPopupOpen6(true);
        setIsPopupOpen5(false);
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 402) {
          toast.error("You Have Insufficient Fund", {
            position: "top-right",
          });
          setIsLoading(false);
        } else {
          toast.error("Error Making Investment", {
            position: "top-right",
          });

          setIsLoading(false);
        }

        setIsLoading(false);
      });
  };

  const handleDepositChange = (event) => {
    setDepositAmount(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`${url}/funding/fetchsingleusersbalance/${userId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTransactions(response.data.balance);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  }, [userId]);

  const totalFinalBalance = transactions.reduce((accumulator, transaction) => {
    return accumulator + transaction.finalBalance;
  }, 0);

  const formattedBalance = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalFinalBalance);

  const ITEMS_PER_PAGE = 5;
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);

  const fundingTypes = [
    "All",
    ...new Set(transactions?.map((transaction) => transaction.fundingtype)),
  ];

  const filteredTransactions = transactions?.filter((transaction) => {
    const matchesSearchTerm = transaction.fundingtype
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesFilter =
      filter === "All" || transaction.fundingtype === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const totalPages = Math.ceil(filteredTransactions?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentTransactions = filteredTransactions?.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleClosePopup1 = () => {
    setIsPopupOpen1(false);
  };

  return (
    <div className="w-full bg-[#00001A] min-h-screen">
      <Navbar />
      <div className="px-4 py-6 flex flex-col md:flex-row gap-6 mt-20">
        <div className="mb-4 w-full">
          <h2 className="text-white text-2xl mb-4">
            Available Balance: {formattedBalance}
          </h2>

          <div className="flex gap-2 pb-4">
            <button
              onClick={handleOpenPopup}
              className=" text-white text-[16px] font-[900] font-[istok] border-white border-[1px] px-4 py-2  rounded"
            >
              Deposit
            </button>
            <button
              onClick={handleOpenPopup5}
              className=" text-white text-[16px] font-[900] font-[istok]  border-white border-[1px] px-4 py-2  rounded"
            >
              Withdraw
            </button>
          </div>

          <div className="flex flex-col md:flex-row gap-4 mb-4">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded w-full md:w-auto"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {fundingTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full mt-4 text-center">
              <thead className="bg-[#D4F5F9]">
                <tr>
                  <th className="py-2 px-4 border-b">TRANSACTION ID</th>
                  <th className="py-2 px-4 border-b">TRANSACTION TYPE</th>
                  <th className="py-2 px-4 border-b">AMOUNT</th>
                  <th className="py-2 px-4 border-b">TYPE</th>
                </tr>
              </thead>
              <tbody>
                {currentTransactions.map((transaction) => (
                  <tr key={transaction.transactionid} className="px-2 py-4">
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {transaction.transactionid}
                    </td>
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {transaction.fundingtype}
                    </td>
                    <td
                      className={`py-2 px-4 ${
                        transaction.finalBalance < 0
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {transaction.finalBalance}
                    </td>
                    <td className="py-2 px-4 text-[#D4F5F9]">
                      {transaction.finalBalance < 0 ? "DEBIT" : "CREDIT"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mt-4">
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400 w-full md:w-auto"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="text-white mt-2 md:mt-0">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="p-2 bg-gray-300 rounded disabled:bg-gray-400 w-full md:w-auto"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <main className="p-4">
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <button
                onClick={handleClosePopup}
                className="absolute top-3 right-3 text-[#D4F5F9] hover:text-white focus:outline-none"
              >
                <FaTimes size={24} />
              </button>
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok]">
                Fund Wallet
              </h2>
              <form
                className="bg-[#23292F] shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="paymentName"
                  >
                    Payment Name
                  </label>
                  <select
                    id="paymentName"
                    value={selectedPayment}
                    onChange={handlePaymentChange}
                    className="shadow-md bg-[#414C56] appearance-none rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="" className="text-[#D4F5F9]">
                      Select Payment
                    </option>
                    {payments.map((payment) => (
                      <option key={payment._id} value={payment.paymentname}>
                        {payment.paymentname}
                      </option>
                    ))}
                  </select>
                </div>
                {paymentAddress && (
                  <div className="mb-4">
                    <label
                      className="block text-[#D4F5F9] tex t-sm font-bold mb-2"
                      htmlFor="paymentAddress"
                    >
                      Payment Address
                    </label>
                    <input
                      id="paymentAddress"
                      type="text"
                      value={paymentAddress}
                      readOnly
                      className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                )}
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="depositAmount"
                  >
                    Deposit Amount
                  </label>
                  <input
                    id="depositAmount"
                    type="number"
                    value={depositAmount}
                    onChange={handleDepositChange}
                    className="shadow appearance-none bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  />
                </div>
                <div className="grid place-items-center place-content-center mt-[20px]">
                  {isLoading ? (
                    <Activityindicator />
                  ) : (
                    <button
                      type="submit"
                      className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
                    >
                      Submit Funding Request
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen1 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="grid place-items-center place-content-center bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <img src={Success} alt="" />
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok] mt-[60px]">
                Payment made successfully, assets will be added to your balance
                once confirmed
              </h2>
              <button
                onClick={handleClosePopup1}
                className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen5 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <button
                onClick={handleClosePopup5}
                className="absolute top-3 right-3 text-[#D4F5F9] hover:text-white focus:outline-none"
              >
                <FaTimes size={24} />
              </button>
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok]">
                Make New Withdrawal
              </h2>
              <ToastContainer />
              <form
                className="bg-[#23292F] shadow-md rounded px-8 pt-6 pb-8 mb-4"
                onSubmit={handleSubmitwithdrawal}
              >
                <div className="mb-4">
                  <label
                    className="block text-[#D4F5F9] text-sm font-bold mb-2"
                    htmlFor="paymentName"
                  >
                    Withdrwal Method
                  </label>
                  <select
                    id="paymentName"
                    value={selectedWithdrwal}
                    onChange={handleWithdrawalChange}
                    className="shadow-md bg-[#414C56] appearance-none rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                    required
                  >
                    <option value="" className="text-[#D4F5F9]">
                      Select Withdrawal Method
                    </option>
                    {withdrawal.map((withdraw) => (
                      <option key={withdraw._id} value={withdraw._id}>
                        {withdraw.methodname}
                      </option>
                    ))}
                  </select>
                </div>
                {withdrawalPlan && (
                  <>
                    <div className="mb-4">
                      <label
                        className="block text-[#D4F5F9] text-sm font-bold mb-2"
                        htmlFor="paymentAddress"
                      >
                        Enter Withdrawal Amount
                      </label>
                      <input
                        id="paymentAddress"
                        type="number"
                        value={withdrawalamount}
                        onChange={handleWithdrawalamountChange}
                        className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-[#D4F5F9] text-sm font-bold mb-2"
                        htmlFor="paymentAddress"
                      >
                        Withdrawal Address
                      </label>
                      <input
                        id="paymentAddress"
                        type="text"
                        value={withdrawalAdress}
                        onChange={handleWithdrawalAddressChange}
                        className="shadow-md  appearance-none  bg-[#414C56] rounded w-full py-2 px-3 text-[#D9D9D9] leading-tight focus:outline-none focus:shadow-outline"
                        required
                      />
                    </div>
                  </>
                )}

                <div className="grid place-items-center place-content-center mt-[20px]">
                  {isLoading ? (
                    <Activityindicator />
                  ) : (
                    <button
                      type="submit"
                      className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
                    >
                      Make Withdrawal Request
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </main>

      <main className="p-4">
        {isPopupOpen6 && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="grid place-items-center place-content-center bg-[#23292F] p-6 rounded shadow-lg relative w-[443px]">
              <img src={Success} alt="" />
              <h2 className="text-xl font-bold mb-4 text-[#D4F5F9] text-center font-[istok] mt-[60px]">
                Withdrawal Request Made successfully
              </h2>
              <button
                onClick={handleClosePopup6}
                className="text-center mt-[20px] bg-[#D4F5F9] hover:bg-[#D4F5F9] text-[#23292F] font-bold py-2 px-4 rounded-[10px] focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default UserTransactions;
